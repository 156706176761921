import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  Observable,
  forkJoin,
  throwError as observableThrowError,
  of,
} from 'rxjs'
import { catchError, first, map, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { CustomError } from '../../constants/errors'
import { FeedbackService } from '../feedback.service'
import { LoggerService } from '../logger.service'
import { RestService } from '../rest.service'
import { CustomQueryEncoderHelper } from './auth.service'

type RestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'OPTIONS'
interface PaginatedResults {
  count: number
  next: string | null
  previous: string | null
  results: any[]
}

interface HubtypeApiMethodParams {
  endpoint: string
  baseUrl?: string
  queryParams?: string | URLSearchParams | { [p: string]: any | any[] }
  data?: any
  version?: string
  displayError?: boolean
  responseType?: string
}

export const NO_VERSIONED_API = 'NO_VERSIONED_API'
@Injectable()
export class HubtypeApiService {
  public BASE_URL = `${environment.baseURL}`

  constructor(
    private restService: RestService,
    private feedbackService: FeedbackService,
    private loggerService: LoggerService
  ) {}

  // TODO: temporal name until we migrate all the deprecated "get" calls to this
  getV2({
    endpoint,
    baseUrl = this.BASE_URL,
    queryParams,
    version = 'v1',
    displayError = true,
    responseType,
  }: HubtypeApiMethodParams) {
    const url = `${baseUrl}/${version}${endpoint}`
    const method: RestMethod = 'GET'
    const options = {
      url: undefined,
      method,
      params: queryParams,
      responseType,
    }
    return this.request(options.method, url, options, displayError)
  }

  // TODO: temporal name until we migrate all the deprecated "post" calls to this
  postV2({
    endpoint,
    baseUrl = this.BASE_URL,
    queryParams,
    data,
    version = 'v1',
    displayError = true,
    responseType,
  }: HubtypeApiMethodParams) {
    const url = `${baseUrl}/${version}${endpoint}`
    const method: RestMethod = 'POST'
    const options = {
      url: undefined,
      method,
      params: queryParams,
      responseType,
      body: data,
    }
    return this.request(options.method, url, options, displayError)
  }

  get(
    endpoint: string,
    queryParams?: string | URLSearchParams | { [p: string]: any | any[] },
    version = 'v1',
    displayError = true,
    responseType?: string
  ) {
    const url = `${this.BASE_URL}/${version}${endpoint}`
    const method: RestMethod = 'GET'
    const options = {
      url: undefined,
      method,
      params: queryParams,
      responseType,
    }
    return this.request(options.method, url, options, displayError)
  }

  post(
    endpoint: string,
    data?: FormData | { [p: string]: any | any[] },
    queryParams?: string | URLSearchParams | { [p: string]: any | any[] },
    version = 'v1',
    displayError = true,
    responseType?: string
  ) {
    const url =
      version === NO_VERSIONED_API
        ? `${this.BASE_URL}${endpoint}`
        : `${this.BASE_URL}/${version}${endpoint}`
    const method: RestMethod = 'POST'
    const options = {
      url: undefined,
      body: data,
      method,
      params: queryParams,
      responseType,
    }
    return this.request(options.method, url, options, displayError)
  }

  put(
    endpoint: string,
    data?: FormData | { [p: string]: any | any[] },
    version = 'v1',
    displayError = true,
    responseType?: string
  ) {
    const url = `${this.BASE_URL}/${version}${endpoint}`
    const method: RestMethod = 'PUT'
    const options = {
      url: undefined,
      body: data,
      method,
      responseType,
    }
    return this.request(options.method, url, options, displayError)
  }

  patch(
    endpoint: string,
    data?: FormData | { [p: string]: any | any[] },
    version = 'v1',
    displayError = true,
    responseType?: string
  ) {
    const url = `${this.BASE_URL}/${version}${endpoint}`
    const method: RestMethod = 'PATCH'
    const options = {
      url: undefined,
      body: data,
      method,
      responseType,
    }
    return this.request(options.method, url, options, displayError)
  }

  delete(
    endpoint: string,
    queryParams?: string | URLSearchParams | { [p: string]: any | any[] },
    body?: FormData | { [p: string]: any | any[] },
    version = 'v1',
    displayError = true,
    responseType?: string
  ) {
    const url = `${this.BASE_URL}/${version}${endpoint}`
    const method: RestMethod = 'DELETE'
    const options = {
      url: undefined,
      body,
      method,
      params: queryParams,
      responseType,
    }
    return this.request(options.method, url, options, displayError)
  }

  getAllPaginatedResults(
    pageSize: number,
    path: string,
    version: string,
    queryParams: { [p: string]: any | any[] } = {},
    displayError = true
  ): Observable<any[]> {
    const makeCall = (page: number): Observable<PaginatedResults> => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const params: HttpParams = new HttpParams({
        fromObject: {
          ...queryParams,
          page,
          page_size: pageSize,
        },
        encoder: new CustomQueryEncoderHelper(),
      })
      return this.get(path, params, version, displayError).pipe(first())
    }

    return makeCall(1).pipe(
      switchMap((firstResponse: PaginatedResults) => {
        if (firstResponse.next === null) {
          return of(firstResponse.results)
        }
        const parallelRequests = paginatedRequestsFactory(firstResponse.count)
        return combineResultsFromParallelResponses(
          forkJoin(parallelRequests),
          firstResponse.results
        )
      })
    )

    function paginatedRequestsFactory(
      totalResultsCount: number
    ): Observable<PaginatedResults>[] {
      const numberOfRequests = Math.ceil(totalResultsCount / pageSize) - 1
      const startingPage = 2
      if (numberOfRequests > 0) {
        return Array.from({ length: numberOfRequests }, (v, index) =>
          makeCall(startingPage + index)
        )
      }
    }

    function combineResultsFromParallelResponses(
      parallelRequests: Observable<PaginatedResults[]>,
      results: any[]
    ): Observable<any[]> {
      return parallelRequests.pipe(
        first(),
        map((responses: PaginatedResults[]) => {
          const parallelResults = responses.map(response => response.results)
          return results.concat(...parallelResults)
        })
      )
    }
  }

  private request(
    method: RestMethod,
    url: string,
    options: { [param: string]: any },
    notifyIfError = true // delete
  ): Observable<any> {
    const htApiError = CustomError.UNHANDLED_ERROR

    if (environment.currentUserCookieEnabled) {
      options.withCredentials = true
    }

    return this.restService.request(method, url, options).pipe(
      catchError(error => {
        if (this.is4xxOr5xxError(error.status)) {
          if (this.isTimeoutError(error.status)) {
            this.loggerService.logHttpError(method, error)
          }

          if (notifyIfError) {
            let errMsg = error
            try {
              if (error.error && error.error.detail) {
                errMsg = { statusText: error.error.detail }
              } else {
                const er = error.json()
                if (er.detail) {
                  errMsg = { statusText: er.detail }
                }
                if (er.email) {
                  errMsg = { statusText: er.email[0] }
                }
              }
            } catch (e) {}
            const errorText = errMsg?.statusText || htApiError.message
            this.feedbackService.error(htApiError.code, errorText, error)
          }
        }
        return observableThrowError(error)
      })
    )
  }

  private is4xxOr5xxError(errorStatus: number): boolean {
    return errorStatus >= 400 && errorStatus <= 511
  }

  private isTimeoutError(errorStatus: number): boolean {
    return errorStatus === 408 || errorStatus === 504
  }
}
